.contract-addition ul {
    list-style: none;
    counter-reset: contract-addition-counter;
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
}

.contract-addition ul li {
    counter-increment: contract-addition-counter;
    display: flex;
    width: 100%;
}

.contract-addition ul li::before {
    content: '0' counter(contract-addition-counter) '.';
    margin-right: .5rem;
    line-height: 1;
}

.contract-addition ul li:nth-child(n+10):before {
    content: counter(contract-addition-counter) '.';
}

.contract-addition ul li:last-child:before {
    content: '';
}
