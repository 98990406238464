.react-confirm-alert-body-element {
    overflow: hidden;
}

.react-confirm-alert-blur {
    filter: blur(2px);
}

.react-confirm-alert {
    width: 100%;
}

.react-confirm-alert-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2000;
    background: rgba(0, 0, 0, .6);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    animation: react-confirm-alert-fadeIn .2s .2s forwards;
}

.react-confirm-alert-body {
    box-sizing: border-box;
    padding: 24px;
    margin: 10px;
    text-align: left;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 20px 75px rgba(0, 0, 0, .13);
    color: #666;
    overflow: hidden;
}

.react-confirm-alert-svg {
    position: absolute;
    top: 0;
    left: 0;
}

.react-confirm-alert-body > h1 {
    margin-top: 0;
    font-size: 26px;
}

.react-confirm-alert-body > h3 {
    margin: 0;
    font-size: 16px;
}

.react-confirm-alert-button-group {
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
}

.react-confirm-alert-button-group > button {
    margin-bottom: 0;
}

@keyframes react-confirm-alert-fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
