.navitem {
    display: block;
    background: #102695;
    text-decoration: none;
    box-sizing: border-box;
    margin: 10px;
    padding: 5px;
}

.navitem .title {
    display: block;
    color: white;
    margin-bottom: 10px;
    font-size: .8em;
}

.navitem img {
    margin: 10px 0;
    height: 80px;
}
