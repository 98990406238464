.project {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.customer-name-container {
    flex: 20;
    text-align: left;
    display: flex;
    flex-direction: column;
}

.project-info-container {
    flex: 1;
    text-align: right;
    display: flex;
    flex-direction: column;
}

.refresh-project{
    flex: 1;
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.project .type {
    flex: 1;
    text-align: right;
    font-size: 14px;
}

.project .number {
    flex: 1;
    text-align: right;
    font-size: 12px;
    padding-top: 3px;
}

.project .customer-name-container .customer {
    font-weight: bold;
    flex: 1;
    font-size: 12px;
}

.project-render-value {
    height: 76px;
    border: 2px solid black;
    margin: 0.3em 0 0.3em;
    display: inline-flex;
    padding: 12px 10px;
    position: relative;
    width: 100%;
    box-sizing: border-box;
    flex-direction: column;
    vertical-align: top;
}

.project-render-value .project{
    margin-top: 10px;
    font-size: 18px;
}

.refresh-project svg:hover{
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.04);
}
