.links-container {
  padding-top: 4vh;
}

.link-item {
  display: flex;
  align-items: center;
}

.link-icon {
  padding: 0 .25em 2.3vh;
  color: #102695;
}

.list-text {
  max-width: fit-content;
  padding-right: .25em;
}

.list-icon {
  color: black;
  padding-bottom: 3%;
}
