.login .video {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    box-sizing: border-box;
}

.login .video video {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    z-index: -1;
    object-fit: cover;
}

.login .logo {
    filter: drop-shadow(0 0 3px rgba(0, 0, 0, .7));
    height: 200px;
}

.login .title {
    display: block;
    color: white;
    filter: drop-shadow(0 0 3px rgba(0, 0, 0, .7));
    margin-top: -40px;
}

.login {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    height: 100vh;
}

.login .main {
    justify-content: space-evenly;
    max-width: 600px;
    min-width: 160px;
    padding: 0;
    width: 90vw;
}

.login .main button {
    margin-top: 180px;
}
